function CommonGround(props) {
	return (
		<div className="flex items-center justify-center h-auto p-4 bg-gray-200">
			<div className="container">
				<div className="flex justify-center">
					<div className="bg-yellow-200 shadow-xl rounded-lg w-5/6">
						<ul className="divide-y divide-gray-500 overflow-y-auto">
							<h2 className="mb-2 text-2xl font-bold text-center text-gray-800 bg-yellow-200">
								{props.type === "for" ? "Case For Main Claim" : "Case Against Main Claim"}
							</h2>
							{props.commonGround.map(ground => {
								return (
									<li
										className="px-4 py-2 hover:bg-yellow-200 cursor-pointer font-serif text-lg bg-yellow-100"
										key={ground._id}>
										{ground.reason}
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CommonGround;
