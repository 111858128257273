import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setRole, emitStartGame } from "../../reducers/gameSlice";

function CreateGameModal(props) {
	const dispatch = useDispatch();

	const claimLimit = 210;
	const [mainClaim, setMainClaim] = useState("");
	const [displayLimit, setDisplayLimit] = useState(claimLimit);

	const isMainClaimValid = mainClaim.length >= 20 ? true : false;

	function handleMainClaim(event) {
		setMainClaim(event.target.value);
		setDisplayLimit(claimLimit - event.target.value.length);
	}

	function createGame() {
		if (isMainClaimValid) {
			dispatch(
				emitStartGame({
					mainClaim: mainClaim,
				})
			);
			dispatch(setRole({ role: "referee" }));
			sessionStorage.setItem("role", "referee");
			props.startCreatedGame();
		}
	}

	return (
		<div className="h-full text-center bg-blue-200 pt-2 flex items-center">
			<div className="flex flex-col w-full">
				<h1 className="pt-4 text-3xl font-medium text-gray-600">Create a Game</h1>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-center gap-2 m-2 text-xl"></div>

					<div className="h-full text-center">
						<form onSubmit={createGame}>
							<span className="text-lg font-medium">Main Claim ({displayLimit})</span>
							<label className="block">
								<textarea
									className={`block lg:w-5/6 w-11/12 h-48
														whitespace-normal
														p-2 mt-2 mx-auto
														overflow-hidden 
														${
															isMainClaimValid
																? "border-2 border-cyan-600 focus:border-3 focus:border-cyan-700"
																: "border-2 border-red-600 focus:border-3 focus:border-red-700"
														}
														rounded-md`}
									onChange={handleMainClaim}
									maxLength={claimLimit}
									placeholder="State your main claim"></textarea>
							</label>
							{!isMainClaimValid ? <div>Main Claim must contain at least 20 characters</div> : null}
							<input
								type="submit"
								className="px-10 py-3 mt-5 text-base font-bold text-white
													bg-blue-500 w-52 rounded-lg hover:shadow hover:bg-blue-800"
								value="Start Game"></input>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreateGameModal;
