import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRole, emitJoinGame } from "../../reducers/gameSlice";
import { emitIsRoomCodeValid, emitIsPlayerNameAvailable } from "../../reducers/appSlice";
import { getRandomName } from "../../utilities/nameGenerator";
// import { ReactComponent as ReloadIcon } from "../../icons/reload.svg";

function JoinGameModal() {
	const dispatch = useDispatch();
	const errorMessage = useSelector(state => state.app.error);
	const [room, setRoom] = useState("");
	const [playerName, setPlayerName] = useState("");
	const isRoomCodeValid = useSelector(state => state.app.roomCodeValid);
	const isPlayerNameAvailable = useSelector(state => state.app.playerNameAvailable);
	const codeLimit = 6;
	const minNameLength = 5;
	const isPlayerNameValid = isPlayerNameAvailable && playerName.length >= minNameLength;
	const nameLimitString =
		"Players are asked not to use real names, but to use the same fake name throughout the semester. Enter a made up name (at least 5 characters) or select “Random Name” to generate one.";

	function handleRoomCode(event) {
		const code = (event.target.value = event.target.value.toUpperCase());
		setRoom(code);
		dispatch(emitIsRoomCodeValid({ roomCode: code }));
	}

	function handlePlayerNameChange(event) {
		const name = event.target.value;
		setPlayerName(name);
		dispatch(emitIsPlayerNameAvailable({ roomCode: room, playerName: name }));
	}

	function getNewPlayerName() {
		setPlayerName(getRandomName());
	}

	function joinGameRoom() {
		if (isPlayerNameValid) {
			dispatch(emitJoinGame({ roomCode: room, playerName: playerName }));
		}
		dispatch(setRole({ role: "player" }));
		sessionStorage.setItem("role", "player");
	}

	const style = {
		roomCodeInput: "text-center py-2 px-2 rounded border-2 border-cyan-600 focus:border-3 focus:border-cyan-700",
		generateNameButton:
			"py-2 px-9 content-center text-slate-100 text-xl font-bold bg-blue-500 hover:bg-blue-600 rounded-md",
		joinGameRoom: "py-2 px-10 content-center text-slate-100 text-xl font-bold bg-blue-500 hover:bg-blue-600 rounded-md",
		playerNameInput: {
			default: "py-2 px-2 rounded text-center",
			isTrue: "border-2 border-cyan-600 focus:border-3 focus:border-cyan-700",
			isFalse: "border-2 border-red-600 focus:border-3 focus:border-red-700",
		},
		errorText: "text-red-600",
	};

	return (
		<div className="h-full text-center bg-blue-200 pt-2 flex items-center">
			<div className="flex flex-col w-full">
				<h1 className="py-4 text-3xl font-medium text-gray-600">Join Game</h1>
				<div className="text-red-600">{errorMessage}</div>
				<div className="flex flex-col items-center gap-4">
					<input
						className={style.roomCodeInput}
						type="text"
						placeholder="Room Code"
						name="room-code"
						maxLength={codeLimit}
						onChange={handleRoomCode}></input>
					{room.length > 0 && !isRoomCodeValid ? <div className={style.errorText}>Room code is not valid</div> : null}
					{isRoomCodeValid ? (
						<div className="flex flex-col items-center gap-4">
							<label>Player Name: </label>
							<input
								className={`${style.playerNameInput.default} ${
									isPlayerNameValid ? style.playerNameInput.isTrue : style.playerNameInput.isFalse
								}`}
								type="text"
								placeholder={"Player name"}
								value={playerName}
								maxLength={25}
								onChange={handlePlayerNameChange}></input>
							{playerName.length < minNameLength ? <div className="errorText">{nameLimitString}</div> : null}
							{playerName.length > 0 && !isPlayerNameAvailable ? (
								<div className="errorText">Someone is already using the name {playerName}</div>
							) : null}
							<button className={style.generateNameButton} onClick={getNewPlayerName}>
								Random Name
							</button>
						</div>
					) : null}
				</div>
				<div className="mt-4">
					{isRoomCodeValid && isPlayerNameValid ? (
						<button className={style.joinGameRoom} onClick={joinGameRoom}>
							Join
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default JoinGameModal;
