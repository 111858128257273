import { ReactComponent as EditIcon } from "../../icons/edit.svg";

function ReasonInPlay(props) {
	const style = {
		button: {
			vote: "px-6 py-3 font-bold text-white rounded",
			move: "py-[5px] px-4 text-center bg-yellow-200 rounded-sm hover:bg-yellow-300 shadow-sm",
		},
		icon: {
			button: "p-[8px] border-2 border-black/50 rounded-md w-[48px] cursor-pointer",
			edit: "hover:bg-slate-300",
		},
		reasonType: "font-bold mt-2",
	};

	const reasonType = props.reason.type === "for" ? "For" : props.reason.type === "against" ? "Against" : "For/Against";

	const didVoteEstablished = !props.reason.establishedVoters
		? false
		: props.reason.establishedVoters.includes(props.player.id);
	const didVoteContested = !props.reason.contestedVoters
		? false
		: props.reason.contestedVoters.includes(props.player.id);

	function triggerOpenEditReasonForm() {
		props.openEditReasonForm("rip");
	}

	function voteEstablished(playerId) {
		props.handleEstablishedReasonInPlayVote(playerId);
	}

	function voteContested(playerId) {
		props.handleContestedReasonInPlayVote(playerId);
	}

	return (
		<div className="flex lg:w-4/6 w-full flex-row mt-2">
			<div className="flex-1 p-1 rounded-lg shadow-lg ">
				<h2 className="mb-2 text-2xl font-bold text-center text-gray-800">Reason-in-Play (RiP)</h2>
				<div className="flex-1 text-center">
					<div className="p-3 m-4 bg-green-100 font-medium text-green-800 text-xl rounded-lg shadow-sm ">
						<div>{props.reason.reason}</div>
						<div className={style.reasonType}>{reasonType}</div>
					</div>
					<div className="flex flex-row justify-center gap-6">
						{props.role === "player" ? (
							<>
								<button
									onClick={() => voteEstablished(props.player.id)}
									className={`${style.button.vote}
										${!didVoteEstablished ? "bg-true-blue" : "bg-gray-600 ring-4 ring-true-blue"}
									`}>
									Established
								</button>
								<button
									onClick={() => voteContested(props.player.id)}
									className={`${style.button.vote}
										${!didVoteContested ? "bg-primary-red" : "bg-gray-600 ring-4 ring-primary-red"}
									`}>
									Contested
								</button>
							</>
						) : null}

						{props.role === "referee" ? (
							<>
								<button className={style.button.move} onClick={props.handleMoveRIPToCommonGround}>
									Common Ground
								</button>
							</>
						) : null}
						{props.role === "referee" || props.reason.playerId === props.player.id ? (
							<>
								<button className={`${style.icon.button} ${style.icon.edit}`} onClick={triggerOpenEditReasonForm}>
									<EditIcon />
								</button>
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ReasonInPlay;
