function DeleteReasonConfirmationModal(props) {
	const style = {
		reason: "m-2",
		button: "py-1 px-4 font-bold text-slate-100 rounded-full",
	};

	function deleteReason() {
		props.deleteReason();
	}

	function closeModal() {
		props.closeModal();
	}

	return (
		<div className="p-2 h-full text-center flex flex-col justify-around bg-slate-50">
			<div>Are you sure you want to delete:</div>
			<div className={style.reason}>{props.reason.reason}</div>
			<div className="flex flex-row justify-center gap-8">
				<button className={`${style.button} bg-red-500 hover:bg-red-700`} onClick={deleteReason}>
					Delete
				</button>
				<button className={`${style.button} bg-sky-500 hover:bg-sky-700`} onClick={closeModal}>
					Cancel
				</button>
			</div>
		</div>
	);
}

export default DeleteReasonConfirmationModal;
