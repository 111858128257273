import { createSlice } from "@reduxjs/toolkit";

export const gameSlice = createSlice({
	name: "game",
	initialState: {
		role: null,
		roomCode: null,
		phase: null,
		player: null,
		mainClaim: null,
		convinced: 0,
		notPersuaded: 0,
		convincedVoters: [],
		notPersuadedVoters: [],
		reasonsToPlay: [],
		reasonInPlay: null,
		commonGrounds: [],
		results: null,
	},
	reducers: {
		setRole: (state, action) => {
			state.role = action.payload.role;
		},
		setRoomCode: (state, action) => {
			return {
				...state,
				roomCode: action.payload.roomCode,
			};
		},
		initGame: (state, action) => {
			return {
				...state,
				roomCode: action.payload.roomCode,
				mainClaim: action.payload.mainClaim,
				phase: action.payload.phase,
				convinced: action.payload.convinced,
				notPersuaded: action.payload.notPersuaded,
			};
		},
		setMainClaimState: (state, action) => {
			return {
				...state,
				mainClaim: action.payload.mainClaim,
				phase: action.payload.phase,
				convinced: action.payload.convinced,
				notPersuaded: action.payload.notPersuaded,
				convincedVoters: action.payload.convincedVoters,
				notPersuadedVoters: action.payload.notPersuadedVoters,
			};
		},
		setPlayer: (state, action) => {
			return {
				...state,
				player: action.payload,
			};
		},
		changeGamePhase: (state, action) => {
			return {
				...state,
				phase: action.payload.phase,
			};
		},
		setGameState: (state, action) => {
			return {
				...state,
				mainClaim: action.payload.room.mainClaim,
				phase: action.payload.room.phase,
				convinced: action.payload.room.convinced,
				notPersuaded: action.payload.room.notPersuaded,
				convincedVoters: action.payload.room.convincedVoters,
				notPersuadedVoters: action.payload.room.notPersuadedVoters,
				reasonsToPlay: action.payload.reasonsToPlay ? action.payload.reasonsToPlay : state.reasonsToPlay,
				reasonInPlay: action.payload.reasonInPlay ? action.payload.reasonInPlay : state.reasonInPlay,
				commonGrounds: action.payload.commonGrounds ? action.payload.commonGrounds : state.commonGrounds,
			};
		},
		updateReasonsToPlay: (state, action) => {
			return {
				...state,
				reasonsToPlay: action.payload,
			};
		},
		addReasonToPlay: (state, action) => {
			const index = state.reasonsToPlay.findIndex(element => element.id === action.payload.id);
			if (index >= 0) {
				state.reasonsToPlay = [
					...state.reasonsToPlay.slice(0, index),
					action.payload,
					...state.reasonsToPlay.slice(index + 1),
				];
			} else {
				state.reasonsToPlay = [...state.reasonsToPlay, action.payload];
			}
		},
		updateReasonToPlay: (state, action) => {
			const index = state.reasonsToPlay.findIndex(reasonToPlay => reasonToPlay.id === action.payload.id);
			if (index >= 0) {
				state.reasonsToPlay = [
					...state.reasonsToPlay.slice(0, index),
					action.payload,
					...state.reasonsToPlay.slice(index + 1),
				];
			}
		},
		deleteReasonToPlay: (state, action) => {
			if (action.payload.reasons.length > 0) {
				const index = state.reasonsToPlay.findIndex(element => element.id === action.payload.id);
				if (index >= 0) {
					state.reasonsToPlay = [
						...state.reasonsToPlay.slice(0, index),
						action.payload,
						...state.reasonsToPlay.slice(index + 1),
					];
				}
			} else {
				const filteredReasonsToPlay = state.reasonsToPlay.filter(reasonToPlay => reasonToPlay.id !== action.payload.id);
				state.reasonsToPlay = [...filteredReasonsToPlay];
			}
		},
		boutBegun: (state, action) => {
			return {
				...state,
				reasonInPlay: action.payload,
			};
		},
		updateReasonInPlay: (state, action) => {
			return {
				...state,
				reasonInPlay: action.payload ? Object.assign({}, state.reasonInPlay, action.payload) : null,
			};
		},
		updatePlayerRIPVoteStatus: (state, action) => {
			return {
				...state,
				reasonInPlay: Object.assign({}, state.reasonInPlay, action.payload),
			};
		},
		updateCommonGrounds: (state, action) => {
			return {
				...state,
				commonGrounds: [...action.payload],
			};
		},
		setFinalResults: (state, action) => {
			return {
				...state,
				results: action.payload,
			};
		},
		updatePriority: (state, action) => {
			return {
				...state,
				reasonToPlay: action.payload,
			};
		},
		emitStartGame: () => {},
		emitJoinGame: () => {},
		emitVote: () => {},
		emitUpdateMainClaim: () => {},
		emitVoteConvinced: () => {},
		emitVoteNotPersuaded: () => {},
		emitAddReasonToPlay: () => {},
		emitBeginBout: () => {},
		emitUpdateReasonToPlay: () => {},
		emitDeleteReasonToPlay: () => {},
		emitUpdateReasonInPlay: () => {},
		emitVoteEstablishedReasonInPlay: () => {},
		emitVoteContestedReasonInPlay: () => {},
		emitMoveRIPToCommonGround: () => {},
		emitChangeGamePhase: () => {},
		emitGetFinalResults: () => {},
		emitUpdatePriority: () => {},
	},
});

export const {
	setRole,
	setRoomCode,
	initGame,
	editMainClaim,
	addReasonToPlay,
	changeGamePhase,
	upVoteEstablishedReasonInPlay,
	upVoteContestedReasonInPlay,
	downVoteEstablishedReasonInPlay,
	downVoteContestedReasonInPlay,
	emitStartGame,
	emitJoinGame,
	emitVote,
	emitUpdateMainClaim,
	emitAddReasonToPlay,
	emitVoteConvinced,
	emitVoteNotPersuaded,
	emitBeginBout,
	emitUpdateReasonToPlay,
	emitDeleteReasonToPlay,
	emitUpdateReasonInPlay,
	emitVoteEstablishedReasonInPlay,
	emitVoteContestedReasonInPlay,
	emitMoveRIPToCommonGround,
	emitChangeGamePhase,
	emitGetFinalResults,
	emitUpdatePriority,
} = gameSlice.actions;

export default gameSlice.reducer;
