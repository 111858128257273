function Results(props) {
	const style = {
		pollsGroup: "m-4 flex flex-wrap justify-center gap-[10px] p-0",
		pollContainer:
			"flex flex-column justify-center border-2 border-zinc-800/60 shadow-lg rounded gap-2 p-2 lg:w-96 sm:shrink-0 w-[100%]",
		pollParent: "w-[100%]",
		pollTitle: "text-xl font-semibold text-center m-0 pt-0 pb-2 border-b-2 border-zinc-900",
		voteTitleGroup: "font-semibold pt-2 pb-2 flex flex-column justify-around border-b-2 border-zinc-900/50",
		votersGroup: "flex flex-column",
		votersListGroup: "flex justify-center w-[100%]",
		votersList: "text-center w-[95%]",
		voter: "py-1.5 border-b-2 border-zinc-700/50",
	};

	return props.results ? (
		<div>
			<div className={style.pollsGroup}>
				{props.results.game.polls.map((poll, index) => {
					return (
						<div className={style.pollContainer} key={index}>
							<div className={style.pollParent}>
								<div className={style.pollTitle}>
									{poll.poll === "strawpoll"
										? `Strawpoll (${poll.convincedVoterCount + poll.notPersuadedVoterCount})`
										: `Endpoll (${poll.convincedVoterCount + poll.notPersuadedVoterCount})`}
								</div>
								<div className={style.voteTitleGroup}>
									<div>Convinced ({poll.convincedVoterCount})</div>
									<div>Not Persuaded ({poll.notPersuadedVoterCount})</div>
								</div>
								<div className={style.votersGroup}>
									<div className={style.votersListGroup}>
										<ul className={style.votersList}>
											{poll.convincedVoters.map((voter, index) => {
												return (
													<li key={index} className={style.voter}>
														{voter}
													</li>
												);
											})}
										</ul>
									</div>
									<div className={style.votersListGroup}>
										<ul className={style.votersList}>
											{poll.notPersuadedVoters.map((voter, index) => {
												return (
													<li key={index} className={style.voter}>
														{voter}
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className="flex flex-column flex-wrap justify-center shadow-lg p-4 rounded mt-2 gap-12">
				<div className="bg-green-50 shadow-lg rounded-lg p-2 border-2 lg:w-96 sm:shrink-0 w-[100%]">
					<div className="text-xl text-center font-semibold py-1">Not Persuaded to Convinced</div>
					<div className="flex flex-col text-center">
						<ul className="text-lg font-serif">
							{props.results.winners && props.results.winners.convinced
								? props.results.winners.convinced.map((winner, index) => {
										return (
											<li
												className="my-2.5 py-1 hover:bg-green-200 focus:ring-2 hover:shadow-lg rounded-md border-2"
												key={index}>
												{winner}
											</li>
										);
								  })
								: null}
						</ul>
					</div>
				</div>
				<div className="bg-red-50 shadow-lg rounded-lg p-2 border-2 lg:w-96 sm:shrink-0 w-[100%]">
					<div className="text-xl text-center font-semibold py-1">Convinced to Not Persuaded</div>
					<div className="flex flex-col text-center">
						<ul className="text-lg font-serif">
							{props.results.winners && props.results.winners.notPersuaded
								? props.results.winners.notPersuaded.map((winner, index) => {
										return (
											<li
												className="my-2.5 py-1 hover:bg-red-200 focus:ring-2 hover:shadow-lg rounded-md border-2"
												key={index}>
												{winner}
											</li>
										);
								  })
								: null}
						</ul>
					</div>
				</div>
			</div>
		</div>
	) : null;
}

export default Results;
