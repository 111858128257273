import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";

const style = {
	reasonContainer: "border-t-2 border-zinc-900",
	reasonType: "font-semibold",
	reasonString: "my-2",
	reasonFlexBox: "my-2 flex flex-col",
	reasonControls: "my-2 flex justify-center gap-x-5",
	reaasonControlItem: "cursor-pointer",
	icon: {
		button: "p-[5px] border-2 border-black/50 rounded-md w-[38px] cursor-pointer",
		edit: "hover:bg-slate-300",
		delete: "hover:bg-red-600",
	},
	controlGroup: "flex justify-center gap-2.5",
	button: "font-bold mb-2 px-4 py-1 text-sm text-slate-50 rounded-full bg-violet-700 hover:bg-violet-800",
};

function Reason(props) {
	const reasonType = props.reason.type === "for" ? "For" : props.reason.type === "against" ? "Against" : "For/Against";

	return (
		<div className={style.reasonContainer}>
			<div className={style.reasonFlexBox}>
				<div className={style.reasonType}>{reasonType}</div>
				<div>{props.reason.reason}</div>
				{props.ownerId === props.playerId ? (
					<div className={style.reasonControls}>
						<button
							className={`${style.icon.button} ${style.icon.edit}`}
							onClick={() => props.openEditReasonForm("rtp", props.playerId, props.reason)}>
							<EditIcon />
						</button>
						<button
							className={`${style.icon.button} ${style.icon.delete}`}
							onClick={() => props.deleteReason(props.playerId, props.reason.id, props.reason.reason)}>
							<DeleteIcon />
						</button>
					</div>
				) : null}
			</div>
			<div className={style.controlGroup}>
				{props.role === "referee" ? (
					<button className={`${style.button}`} onClick={() => props.handleBeginBout(props.ownerId, props.reason.id)}>
						Begin Bout
					</button>
				) : null}
				{props.role === "referee" ? (
					<button
						className={`${style.button}`}
						onClick={() => props.handlePin(props.ownerId, props.reason.id, props.reason.reason)}>
						Pin
					</button>
				) : null}
			</div>
		</div>
	);
}

export default Reason;
