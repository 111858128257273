import ReasonToPlay from "./ReasonToPlay";

const style = {
	setContainer: "m-3 flex flex-row flex-wrap gap-x-3",
	header: "mb-2 mt-1 text-2xl font-bold text-center text-gray-800",
	reasonContainer:
		"m-1 p-2 w-96 h-fit flex flex-col justify-between border border-gray-400 text-center shadow rounded-lg hover:bg-gray-200",
};

function ReasonToPlaySet(props) {
	return (
		<div>
			<h2 className={style.header}>Reasons-2-Play (R2P)</h2>
			<div className={style.setContainer}>
				{props.reasonsToPlay.map(reasonToPlay => (
					<ReasonToPlay
						key={reasonToPlay.id}
						role={props.role}
						playerId={props.playerId}
						reasonToPlay={reasonToPlay}
						handlePin={props.handlePin}
						handleBeginBout={props.handleBeginBout}
						openEditReasonForm={props.openEditReasonForm}
						deleteReasonToPlay={props.deleteReasonToPlay}
					/>
				))}
			</div>
		</div>
	);
}

export default ReasonToPlaySet;
