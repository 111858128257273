import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	changeGamePhase,
	emitUpdateMainClaim,
	emitVoteConvinced,
	emitVoteNotPersuaded,
	emitAddReasonToPlay,
	emitBeginBout,
	emitUpdateReasonToPlay,
	emitDeleteReasonToPlay,
	emitUpdateReasonInPlay,
	emitVoteEstablishedReasonInPlay,
	emitVoteContestedReasonInPlay,
	emitMoveRIPToCommonGround,
	emitChangeGamePhase,
	emitGetFinalResults,
	emitUpdatePriority,
} from "../../reducers/gameSlice";

import Scrimmage from "../containers/Scrimmage";
import MainClaim from "../containers/MainClaim";
import EditReason from "../containers/EditReason";
import ReasonToPlaySet from "../containers/ReasonToPlaySet";
import ReasonInPlay from "../containers/ReasonInPlay";
import CommonGrounds from "../containers/CommonGrounds";
import Results from "../containers/Results";

function Game() {
	const dispatch = useDispatch();
	const roomCode = useSelector(state => state.game.roomCode);
	const phase = useSelector(state => state.game.phase);
	const role = useSelector(state => state.game.role);
	const player = useSelector(state => state.game.player);
	const playerName = role === "referee" ? "" : player.name;
	const mainClaim = useSelector(state => state.game.mainClaim);
	const convincedVoteCount = useSelector(state => state.game.convinced);
	const notPersuadedVoteCount = useSelector(state => state.game.notPersuaded);
	const convincedVoters = useSelector(state => state.game.convincedVoters);
	const notPersuadedVoters = useSelector(state => state.game.notPersuadedVoters);
	const reasonsToPlay = useSelector(state => state.game.reasonsToPlay);
	const reasonInPlay = useSelector(state => state.game.reasonInPlay);
	const commonGrounds = useSelector(state => state.game.commonGrounds);
	const results = useSelector(state => state.game.results);
	const [reasonToEdit, setReasonToEdit] = useState(null);
	const [reasonToEditType, setReasonToEditType] = useState(null);
	const [reasonToEditPriority, setReasonToPriority] = useState(null);
	const [sortedReasonsToPlay, setSortedReasonsToPlay] = useState([]);

	const phases = [
		{ value: "land", text: "Land" },
		{ value: "strawpoll", text: "Strawpoll" },
		{ value: "saywhy", text: "SayWhy" },
		{ value: "bout", text: "Bout" },
		{ value: "endpoll", text: "Endpoll" },
	];

	const didVoteConvinced = convincedVoters.includes(player.id);
	const didVoteNotPersuaded = notPersuadedVoters.includes(player.id);

	useEffect(() => {
		// Ensure the current user reasonsToPlay appear first on the list of reasonsToPlay
		const currentUserReasonToPlay = reasonsToPlay.find(reasonsToPlaySet => reasonsToPlaySet.id === player.id);
		if (currentUserReasonToPlay) {
			const sortedReasons = reasonsToPlay.filter(reasonToPlaySet => reasonToPlaySet.id !== currentUserReasonToPlay.id);
			sortedReasons.sort((a, b) => {
				return a.id.localeCompare(b.id);
			});
			sortedReasons.unshift(currentUserReasonToPlay);
			setSortedReasonsToPlay(sortedReasons);
		} else {
			setSortedReasonsToPlay(reasonsToPlay);
		}
	}, [reasonsToPlay, player]);

	function handleConvincedVote() {
		const data = {
			roomCode: roomCode,
			playerId: player.id,
		};
		dispatch(emitVoteConvinced(data));
	}

	function handleNotPersuadedVote() {
		const data = {
			roomCode: roomCode,
			playerId: player.id,
		};
		dispatch(emitVoteNotPersuaded(data));
	}

	function handleUpdateMainClaim(mainClaimString) {
		dispatch(
			emitUpdateMainClaim({
				roomCode: roomCode,
				mainClaim: mainClaimString,
			})
		);
	}

	function sendNewReasonToPlay(reason) {
		dispatch(
			emitAddReasonToPlay({
				roomCode: roomCode,
				playerId: player.id,
				...reason,
			})
		);
	}

	function handleBeginBout(playerId, reasonId) {
		if (phase !== "bout") {
			handleChangeGamePhase("bout");
		}
		dispatch(
			emitBeginBout({
				roomCode: roomCode,
				playerId: playerId,
				reasonId: reasonId,
			})
		);
	}

	function updateReasonToPlay(playerId, reasonId, reason) {
		dispatch(
			emitUpdateReasonToPlay({
				roomCode: roomCode,
				playerId: playerId,
				reasonId: reasonId,
				...reason,
			})
		);
	}

	function updatePriority(playerId, reasonId, reason) {
		dispatch(emitUpdatePriority({ roomCode: roomCode, playerId: playerId, reasonId: reasonId, ...reason }));
	}

	function deleteReasonToPlay(reasonToDelete) {
		dispatch(
			emitDeleteReasonToPlay({
				roomCode: roomCode,
				playerId: reasonToDelete.playerId,
				reasonId: reasonToDelete.reasonId,
			})
		);
	}

	function updateReasonInPlay(playerId, reasonId, reason) {
		dispatch(
			emitUpdateReasonInPlay({
				roomCode: roomCode,
				playerId: playerId,
				reasonId: reasonId,
				...reason,
			})
		);
	}

	function openEditReasonForm(reasonType, playerId, reason) {
		let reasonToEdit;
		if (reasonType === "rip") {
			reasonToEdit = reasonInPlay;
		} else {
			const reasonToPlay = reasonsToPlay.find(reasonToPlay => reasonToPlay.id === playerId);
			reasonToEdit = reasonToPlay.reasons.find(element => element.id === reason.id);
		}
		setReasonToEdit(reasonToEdit);
		setReasonToEditType(reasonType);
	}

	function removeReasonToEdit() {
		setReasonToEdit(null);
		setReasonToEditType(null);
	}

	function handleEstablishedReasonInPlayVote(playerId) {
		dispatch(emitVoteEstablishedReasonInPlay({ roomCode: roomCode, playerId: playerId }));
	}

	function handleContestedReasonInPlayVote(playerId) {
		dispatch(emitVoteContestedReasonInPlay({ roomCode: roomCode, playerId: playerId }));
	}

	function handleMoveRIPToCommonGround() {
		dispatch(
			emitMoveRIPToCommonGround({
				roomCode: roomCode,
			})
		);
	}

	function handleChangeGamePhase(value) {
		dispatch(changeGamePhase({ phase: value }));
		dispatch(emitChangeGamePhase({ roomCode: roomCode, phase: value }));
	}

	function handleGetFinalResults() {
		dispatch(emitGetFinalResults({ roomCode: roomCode }));
	}

	function handleDownloadResults() {
		const blob = new Blob([JSON.stringify(results, null, 4)], { type: "application/json" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = `tug-o-logic-${roomCode}.json`;
		link.click();
		link.remove();
	}

	return (
		<div className="lg:relative lg:flex lg:min-h-screen">
			<div className="flex-1  m-1">
				<div className="flex-1">
					<div className={`text-center bg-grey-100 border rounded-lg shadow-lg pt-0.5 mt-0.5 pb-3`}>
						<MainClaim
							role={role}
							phase={phase}
							phases={phases}
							roomCode={roomCode}
							mainClaim={mainClaim}
							playerName={playerName}
							didVoteConvinced={didVoteConvinced}
							didVoteNotPersuaded={didVoteNotPersuaded}
							convinced={convincedVoteCount}
							notPersuaded={notPersuadedVoteCount}
							results={results}
							handleUpdateMainClaim={handleUpdateMainClaim}
							handleConvincedVote={handleConvincedVote}
							handleNotPersuadedVote={handleNotPersuadedVote}
							handleChangeGamePhase={handleChangeGamePhase}
							handleGetFinalResults={handleGetFinalResults}
							handleDownloadResults={handleDownloadResults}
						/>
					</div>
					<div className="flex flex-row w-full">
						{reasonInPlay && phase !== "endpoll" ? (
							<>
								<ReasonInPlay
									role={role}
									player={player}
									reason={reasonInPlay}
									openEditReasonForm={openEditReasonForm}
									handleEstablishedReasonInPlayVote={handleEstablishedReasonInPlayVote}
									handleContestedReasonInPlayVote={handleContestedReasonInPlayVote}
									handleMoveRIPToCommonGround={handleMoveRIPToCommonGround}
								/>
								<Scrimmage
									establishedVoteCount={reasonInPlay.established}
									contestedVoteCount={reasonInPlay.contested}
								/>
							</>
						) : null}
					</div>

					<div className="grid grid-cols-1 lg:grid-cols-3">
						{phase !== "endpoll" ? (
							<div className="">
								<EditReason
									player={player}
									reason={reasonToEdit}
									reasonType={reasonToEditType}
									priority={reasonToEditPriority}
									updateReasonToPlay={updateReasonToPlay}
									updateReasonInPlay={updateReasonInPlay}
									sendNewReasonToPlay={sendNewReasonToPlay}
									removeReasonToEdit={removeReasonToEdit}
								/>
							</div>
						) : null}

						<CommonGrounds commonGrounds={commonGrounds} />
					</div>

					{phase !== "endpoll" ? (
						<ReasonToPlaySet
							role={role}
							playerId={player.id}
							reasonsToPlay={sortedReasonsToPlay}
							handlePin={updatePriority}
							handleBeginBout={handleBeginBout}
							openEditReasonForm={openEditReasonForm}
							deleteReasonToPlay={deleteReasonToPlay}
						/>
					) : null}
					{results !== null ? <Results results={results} /> : null}
				</div>
			</div>
		</div>
	);
}

export default Game;
