import { useState } from "react";
import Modal from "react-modal";

import Reason from "./Reason";
import DeleteReasonConfirmationModal from "../modals/DeleteReasonConfirmationModal";

function ReasonToPlay(props) {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [reasonToDelete, setReasonToDelete] = useState(null);

	const style = {
		author: "pb-2 font-bold",
		modal: "mx-auto my-44 relative w-[30%] h-60 rounded-lg overflow-hidden border-4",
		reasonContainer:
			"m-1 p-2 w-96 h-fit flex flex-col justify-between border border-gray-400 text-center shadow rounded-lg hover:bg-gray-200",
	};

	function openDeleteModal() {
		setIsDeleteModalOpen(true);
	}

	function closeDeleteModal() {
		setIsDeleteModalOpen(false);
	}

	function deleteReason(playerId, reasonId, reason) {
		if (isDeleteModalOpen) {
			props.deleteReasonToPlay(reasonToDelete);
			closeDeleteModal();
		} else {
			setReasonToDelete({ playerId: playerId, reasonId: reasonId, reason: reason });
			openDeleteModal();
		}
	}

	return (
		<div className={style.reasonContainer}>
			<div className="flex flex-col flex-wrap justify-around">
				<div className={style.author}>Author: {props.reasonToPlay.playerName}</div>

				{props.reasonToPlay.reasons.length > 0 &&
					[...props.reasonToPlay.reasons]
						.sort((a, b) => Number(b.priority) - Number(a.priority))
						.map(reason => (
							<Reason
								key={reason.id}
								role={props.role}
								playerId={props.playerId}
								ownerId={props.reasonToPlay.id}
								reason={reason}
								handleBeginBout={props.handleBeginBout}
								openEditReasonForm={props.openEditReasonForm}
								deleteReason={deleteReason}
								handlePin={props.handlePin}
							/>
						))}
			</div>
			<Modal
				className={style.modal}
				isOpen={isDeleteModalOpen}
				onRequestClose={closeDeleteModal}
				contentLabel="Delete?">
				<DeleteReasonConfirmationModal
					reason={reasonToDelete}
					deleteReason={deleteReason}
					closeModal={closeDeleteModal}
				/>
			</Modal>
		</div>
	);
}

export default ReasonToPlay;
