import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
	name: "app",
	initialState: {
		roomCodeValid: false,
		playerNameAvailable: true,
		error: null,
		connectedToSocket: false,
	},
	reducers: {
		setRoomCodeValid: (state, action) => {
			return {
				...state,
				roomCodeValid: action.payload.isRoomCodeValid,
			};
		},
		setPlayerNameAvailable: (state, action) => {
			return {
				...state,
				playerNameAvailable: action.payload.isPlayerNameAvailable,
			};
		},
		setError: (state, action) => {
			return {
				...state,
				error: action.payload.message,
			};
		},
		clearError: state => {
			return {
				...state,
				error: null,
			};
		},
		setConnectedToSocket: (state, action) => {
			return {
				...state,
				connectedToSocket: action.payload.connectedToSocket,
			};
		},
		emitIsRoomCodeValid: () => {},
		emitIsPlayerNameAvailable: () => {},
	},
});

export const { emitIsRoomCodeValid, emitIsPlayerNameAvailable, setConnectedToSocket } = appSlice.actions;

export default appSlice.reducer;
