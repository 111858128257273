import { useState, useEffect, useRef } from "react";

function EditReason(props) {
	const maxReasonLimit = 210;
	const [editedReason, setEditedReason] = useState("");
	const [editedType, setEditedType] = useState("for");
	const [editedPriority, setEditedPriority] = useState(-1);
	const [displayLimit, setDisplayLimit] = useState(maxReasonLimit);
	const reasonTextareaRef = useRef(null);

	const isReasonValid = editedReason && editedReason.length >= 20 ? true : false;

	const style = {
		select:
			"p-1 block text-center text-gray-700 bg-white border-[2px] rounded-md border-sky-500 focus:border-sky-500 focus:border-[3px]",
		caseContainer: "m-1 p-1 flex flex-row justify-center items-center gap-2",
		button: "px-4 py-2 rounded font-bold text-slate-100",
		textarea:
			"h-44 w-full mt-1 mb-1 p-3 form-textarea rounded border-2 border-cyan-600 focus:border-3 focus:border-cyan-700",
		option: "flex flex-col items-start gap-y-[5px]",
		optionTitle: "font-semibold",
	};

	useEffect(() => {
		if (props.reason) {
			setEditedReason(props.reason.reason);
			setEditedType(props.reason.type);
		}
		if (reasonTextareaRef.current) {
			reasonTextareaRef.current.focus();
		}
	}, [props.reason]);

	function handleEditReason(event) {
		setEditedReason(event.target.value);
		setDisplayLimit(maxReasonLimit - event.target.value.length);
	}

	function updateReason() {
		if (isReasonValid) {
			const _reason = {
				reason: editedReason,
				type: editedType,
				priority: editedPriority,
			};
			if (props.reason) {
				if (props.reasonType === "rtp") {
					props.updateReasonToPlay(props.player.id, props.reason.id, _reason);
				} else {
					props.updateReasonInPlay(props.reason.playerId, props.reason.id, _reason);
				}
			} else {
				props.sendNewReasonToPlay(_reason);
			}
			setEditedReason("");
			setEditedType("for");
			setEditedPriority(-1);
			setDisplayLimit(maxReasonLimit);
			props.removeReasonToEdit();
		}
	}

	function changeEditedType(event) {
		setEditedType(event.target.value);
	}
	function clearReasonToPlay() {
		setEditedReason("");
		setEditedType("for");
		setEditedPriority(-1);
		props.removeReasonToEdit();
	}

	return (
		<div className="h-full flex-1 p-2 rounded-lg shadow-lg ">
			<h2 className="mb-2 lg:text-2xl text-base font-bold text-center text-gray-800">
				Suggest/Edit a Reason-2-Play (R2P)
			</h2>
			<div className="flex-1 text-center">
				<legend className="block mb-2 text-center text-gray-700 lg:text-base text-xs">
					Enter Reason for or against the main claim ({displayLimit}). Submit reasons one-by-one so each can be voted on
					individually
				</legend>
				<textarea
					className={style.textarea}
					value={editedReason}
					onChange={handleEditReason}
					maxLength={maxReasonLimit}
					ref={reasonTextareaRef}
					placeholder="Submit your Reason for or against the Main Claim. Use only one single sentence (truth-claim) per Reason"
				/>
				{editedReason && !isReasonValid ? <div>Reason must be at least 20 characters in length</div> : null}
				<div className={style.caseContainer}>
					<span className={style.optionTitle}>Argument Type: </span>
					<div className={style.option}>
						<div>
							<input
								type="radio"
								name="argumentType"
								value="for"
								checked={editedType === "for"}
								onChange={changeEditedType}
							/>
							<label> For</label>
						</div>
						<div>
							<input
								type="radio"
								name="argumentType"
								value="against"
								checked={editedType === "against"}
								onChange={changeEditedType}
							/>
							<label> Against</label>
						</div>
						<div>
							<input
								type="radio"
								name="argumentType"
								value="both"
								checked={editedType === "both"}
								onChange={changeEditedType}
							/>
							<label> For/Against</label>
						</div>
					</div>
				</div>
				<div className="flex gap-3 justify-center">
					<button className={`${style.button} bg-blue-600 hover:bg-blue-700`} onClick={updateReason}>
						Submit
					</button>
					<button onClick={clearReasonToPlay} className={`${style.button} bg-red-600 hover:bg-red-700`}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

export default EditReason;
