import { useState } from "react";
import Modal from "react-modal";

import CreateGameModal from "../modals/CreateGameModal";
import JoinGameModal from "../modals/JoinGameModal";

Modal.setAppElement("#root");

function Home() {
	const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
	const [joinModalIsOpen, setJoinModalIsOpen] = useState(false);

	const style = {
		modal: "mx-auto relative lg:w-1/2 md:w-2/3 w-full lg:h-4/6 h-full lg:my-28 rounded-lg overflow-hidden",
		button: "w-52 px-10 py-3 text-xl font-bold text-white bg-blue-500 rounded-md hover:shadow hover:bg-blue-800",
	};

	function openCreateGameModal() {
		setCreateModalIsOpen(true);
	}

	function closeCreateGameModal() {
		setCreateModalIsOpen(false);
	}

	function startCreatedGame() {
		closeCreateGameModal();
	}

	function openJoinGameModal() {
		setJoinModalIsOpen(true);
	}

	function closeJoinGameModal() {
		setJoinModalIsOpen(false);
	}

	function joinCreatedGame() {
		closeJoinGameModal();
	}

	return (
		<div className="flex flex-col items-center justify-center h-screen mx-auto bg-blue-200 gap-14">
			<button onClick={openCreateGameModal} className={style.button}>
				Create Game
			</button>
			<Modal
				isOpen={createModalIsOpen}
				onRequestClose={closeCreateGameModal}
				contentLabel="Create Game Modal"
				className={style.modal}>
				<CreateGameModal closeCreateGameModal={closeCreateGameModal} startCreatedGame={startCreatedGame} />
			</Modal>
			<button onClick={openJoinGameModal} className={style.button}>
				Join Game
			</button>
			<Modal
				isOpen={joinModalIsOpen}
				onRequestClose={closeJoinGameModal}
				contentLabel="Join Game Modal"
				className={style.modal}>
				<JoinGameModal closeJoinGameModal={closeJoinGameModal} joinCreatedGame={joinCreatedGame} />
			</Modal>
		</div>
	);
}

export default Home;
