import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import createSocketMiddleware from "../reducers/middleware/createSocketMiddleware";

import appReducer from "../reducers/appSlice";
import gameReducer from "../reducers/gameSlice";

const socketMiddleware = createSocketMiddleware();

export default configureStore({
	reducer: {
		app: appReducer,
		game: gameReducer,
	},
	middleware: [...getDefaultMiddleware(), socketMiddleware],
});
