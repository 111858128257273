import { useState, useEffect } from "react";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";

function MainClaim(props) {
	const [editedMainClaim, setEditedMainClaim] = useState("");
	const [editingMainClaim, setEditingMainClaim] = useState(false);
	const isMainClaimValid = editedMainClaim && editedMainClaim.length >= 20 ? true : false;

	const convincedPercentage =
		props.convinced && props.convinced !== 0
			? Math.round((props.convinced / (props.convinced + props.notPersuaded)) * 100)
			: 0;
	const notPersuadedPercentage =
		props.notPersuaded && props.notPersuaded !== 0
			? Math.floor((props.notPersuaded / (props.notPersuaded + props.convinced)) * 100)
			: 0;

	const isVotingEnabled = props.phase === "strawpoll" || props.phase === "endpoll";

	const isVotingDisabledStyle = isVotingEnabled ? "" : "cursor-not-allowed";

	const style = {
		select:
			"py-1 block text-center text-gray-700 bg-white border-[2px] border-sky-500 rounded-md focus:border-[3px] focus:border-sky-500",
		button: "py-1.5 px-5 rounded font-bold text-white",
		phasesContainer: "w-[30em] h-[6em] p-0 m-0 flex flex-col justify-around items-center",
		phasesBar: "w-96 h-[1.8em] flex justify-between items-center border-2 border-black-200 rounded-full",
		phasesCircle: "w-[1.7em] h-full border-2 border-green-400 rounded-full",
		phaseActive: "bg-green-400",
		phaseInactive: "border-green-400 bg-green-50",
		phaseButtonContainer: "w-[80%] h-[30%] flex justify-center gap-x-4",
		phaseButton: "w-[30%] font-bold px-4 text-sm text-slate-50 rounded-full",
		endpollButtons: "bg-indigo-400 hover:bg-indigo-500 rounded-md py-1.5 px-2.5 shadow-sm",
	};

	useEffect(() => {
		if (props.mainClaim) {
			setEditedMainClaim(props.mainClaim);
		}
	}, [props.mainClaim]);

	function switchEditing() {
		setEditingMainClaim(!editingMainClaim);
	}

	function handleEditMainClaim(event) {
		setEditedMainClaim(event.target.value);
	}

	function updateMainClaim() {
		if (isMainClaimValid) {
			props.handleUpdateMainClaim(editedMainClaim);
			switchEditing();
		}
	}

	function moveToPreviousPhase() {
		let index = props.phases.findIndex(element => element.value === props.phase);
		if (index > 0) {
			index--;
			const phaseValue = props.phases.at(index).value;
			props.handleChangeGamePhase(phaseValue);
		}
	}

	function moveToNextPhase() {
		let index = props.phases.findIndex(element => element.value === props.phase);
		if (index < props.phases.length - 1) {
			index++;
			const phaseValue = props.phases.at(index).value;
			props.handleChangeGamePhase(phaseValue);
		}
	}

	function isCurrentPhase(phase) {
		return props.phase === phase;
	}

	function getPreviousPhaseLabel() {
		const index = props.phases.findIndex(element => element.value === props.phase);
		return index > 0 ? props.phases.at(index - 1).text : "";
	}

	function getCurrentPhaseLabel() {
		const index = props.phases.findIndex(element => element.value === props.phase);
		return props.phases.at(index).text;
	}

	function getNextPhaseLabel() {
		const index = props.phases.findIndex(element => element.value === props.phase);
		return index === props.phases.length - 1 ? "" : props.phases.at(index + 1).text;
	}

	return (
		<div>
			{props.phase === "endpoll" ? (
				<div className="p-2 text-3xl shadow-lg border-4 rounded-md font-bold">Final Poll</div>
			) : null}
			{editingMainClaim !== true ? (
				<>
					<div>
						<div className="w-[90%] m-auto text-center mt-2 lg:text-2xl text-lg font-semibold text-pure-black">
							<div>
								<span className="lg:inline hidden">Main Claim:</span> {props.mainClaim}
							</div>
						</div>
						{props.role === "referee" ? (
							<div className="flex justify-center m-auto py-[.2em] gap-6 w-[35%]">
								<button
									hidden={props.phase !== "endpoll"}
									className={style.endpollButtons}
									onClick={props.handleGetFinalResults}>
									Display Results
								</button>

								<div className="p-1 border-2 border-slate-400 rounded-lg" onClick={switchEditing}>
									<EditIcon className="w-[25px] cursor-pointer" />
								</div>

								<button
									hidden={props.phase !== "endpoll" || props.results == null}
									className={style.endpollButtons}
									onClick={props.handleDownloadResults}>
									Download Results
								</button>
							</div>
						) : null}

						<div className="flex justify-center gap-1 pb-0.5">
							<div className={style.phasesContainer}>
								<div className={style.phasesBar}>
									{props.phases.map(phase => {
										return (
											<div
												className={`${style.phasesCircle} ${
													isCurrentPhase(phase.value) ? style.phaseActive : style.phaseInactive
												}`}></div>
										);
									})}
								</div>
								<div className={style.phaseButtonContainer}>
									{props.role === "referee" ? (
										<>
											<button
												className={`${style.phaseButton} bg-emerald-500 hover:bg-emerald-600`}
												onClick={() => moveToPreviousPhase()}>
												{`< ${getPreviousPhaseLabel()}`}
											</button>
										</>
									) : null}

									<button className={`${style.phaseButton} bg-slate-400`} disabled={true}>
										{getCurrentPhaseLabel()}
									</button>

									{props.role === "referee" ? (
										<>
											<button
												className={`${style.phaseButton} bg-emerald-500 hover:bg-emerald-600`}
												onClick={() => moveToNextPhase()}>
												{`${getNextPhaseLabel()} >`}
											</button>
										</>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<textarea
						className="m-2 p-2 w-[95%] border-2 border-slate-400 rounded-md"
						value={editedMainClaim}
						onChange={handleEditMainClaim}></textarea>
					{!isMainClaimValid ? <div>Main claim must be at least 20 characters in length</div> : null}
					<div className="flex justify-center gap-5 pt-2">
						<button className={`${style.button} bg-blue-600 hover:bg-blue-700`} onClick={updateMainClaim}>
							Update
						</button>
						<button onClick={switchEditing} className={`${style.button} bg-red-600 hover:bg-red-700`}>
							Cancel
						</button>
					</div>
				</>
			)}

			<div className="flex justify-center">
				<div className="flex flex-col pt-1">
					<div className="flex flex-row mb-1 items-center justify-between">
						<div className="text-left mr-2">
							<span className="text-xs lg:font-semibold inline-block text-lighter-blue">
								{convincedPercentage}% Convinced {props.convinced} Votes
							</span>
						</div>
						<div className="text-right ml-2">
							<span className="text-xs lg:font-semibold inline-block text-persuaded-green">
								{notPersuadedPercentage}% Not Yet Persuaded {props.notPersuaded} Votes
							</span>
						</div>
					</div>
					<div
						className={`h-4 w-full mb-2 text-xs flex rounded-lg ${
							notPersuadedPercentage ? "bg-persuaded-green" : "bg-neutral-gray"
						}`}>
						<div
							style={{ width: `${convincedPercentage}%` }}
							className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-lg bg-lighter-blue"></div>
					</div>
				</div>
			</div>

			<div>
				{props.role === "player" ? (
					<div className="flex justify-center gap-9 p-2">
						<button
							className={`${style.button}
								${
									!props.didVoteConvinced
										? `bg-lighter-blue ${isVotingDisabledStyle}`
										: `bg-gray-600 ring-4 ring-lighter-blue/50 ${isVotingDisabledStyle}`
								}
							`}
							disabled={!isVotingEnabled}
							onClick={props.handleConvincedVote}>
							Convinced
						</button>
						<button
							className={`${style.button}
								${
									!props.didVoteNotPersuaded
										? `bg-persuaded-green ${isVotingDisabledStyle}`
										: `bg-gray-600 ring-4 ring-persuaded-green/50 ${isVotingDisabledStyle}`
								}
							`}
							disabled={!isVotingEnabled}
							onClick={props.handleNotPersuadedVote}>
							{props.phase === "endpoll" ? "(Still) Not Persuaded" : "Not (yet) Persuaded"}
						</button>
					</div>
				) : null}
			</div>

			{props.playerName ? <span className="lg:text-2xl text-base px-4">Player: {props.playerName}</span> : null}
			{
				<span className="lg:text-2xl text-base px-4">
					Room Code: <span className="ring-2 bg-blue-50 select-all rounded">{props.roomCode}</span>{" "}
				</span>
			}
		</div>
	);
}

export default MainClaim;
