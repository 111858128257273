import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL);

const createSocketMiddleware = () => {
	/**
	 * Data emitted from the server should be placed here.
	 * use storeAPI.dispatch to send the data into redux state
	 */
	// returns a room code on app request
	return storeAPI => {
		const tryReconnect = function () {
			if (!socket.connected) {
				socket.connect();
			}
		};

		let connectionInterval;

		// Handles socket connection event
		socket.on("connect", () => {
			clearInterval(connectionInterval);
			// const roomCode = storeAPI.getState().game.roomCode;
			storeAPI.dispatch({
				type: "app/setConnectedToSocket",
				payload: { connectedToSocket: true },
			});
			const roomCode = sessionStorage.getItem("roomCode");
			const role = sessionStorage.getItem("role");
			const playerId = sessionStorage.getItem("playerId");
			const playerName = sessionStorage.getItem("playerName");
			if (role !== "undefined") {
				storeAPI.dispatch({
					type: "game/setRole",
					payload: {
						role: role,
					},
				});
			}
			if (roomCode) {
				socket.emit("joinRoom", { roomCode: roomCode });
			}
			if (playerId !== "undefined" && playerName !== "undefined") {
				storeAPI.dispatch({
					type: "game/setPlayer",
					payload: {
						id: playerId,
						name: playerName,
					},
				});
			}
		});

		// Handle socket disconnection event. Set a timer to reconnect
		socket.on("disconnect", () => {
			connectionInterval = setInterval(tryReconnect, 1500);
			storeAPI.dispatch({
				type: "app/setConnectedToSocket",
				payload: { connectedToSocket: false },
			});
		});

		socket.on("room code", data => {
			storeAPI.dispatch({
				type: "game/setRoomCode",
				payload: data,
			});
		});

		// starts a game on player request
		socket.on("game created", data => {
			sessionStorage.setItem("roomCode", data.roomCode);
			storeAPI.dispatch({
				type: "game/initGame",
				payload: data,
			});
		});

		// sets the validity of a player insertedroom code
		socket.on("game validity", data => {
			storeAPI.dispatch({
				type: "app/setRoomCodeValid",
				payload: data,
			});
		});

		// sets the validity of a player insertedroom code
		socket.on("name available", data => {
			storeAPI.dispatch({
				type: "app/setPlayerNameAvailable",
				payload: data,
			});
		});

		// sets the player details when player joins a game
		socket.on("player details", data => {
			sessionStorage.setItem("playerId", data.id);
			sessionStorage.setItem("playerName", data.name);
			storeAPI.dispatch({
				type: "game/setPlayer",
				payload: data,
			});
		});

		// send the player to a game room
		socket.on("send to room", data => {
			sessionStorage.setItem("roomCode", data.roomCode);
			storeAPI.dispatch({
				type: "app/clearError",
			});
			storeAPI.dispatch({
				type: "game/setRoomCode",
				payload: data,
			});
		});

		// used to update the game state
		socket.on("game state", data => {
			storeAPI.dispatch({
				type: "game/setGameState",
				payload: data,
			});
		});

		// used to the main claim and its votes
		socket.on("main claim vote", data => {
			storeAPI.dispatch({
				type: "game/setMainClaimState",
				payload: data,
			});
		});

		// changes the phase of the game
		socket.on("phase change", data => {
			storeAPI.dispatch({
				type: "game/changeGamePhase",
				payload: data,
			});
		});

		// adds a reason to play to the app state
		socket.on("add reason", data => {
			storeAPI.dispatch({
				type: "game/addReasonToPlay",
				payload: data,
			});
		});

		// sends a reason-to-play that has been editted to the redux store
		socket.on("update rtp", data => {
			storeAPI.dispatch({
				type: "game/updateReasonToPlay",
				payload: data,
			});
		});

		// sends a reason-to-play to be deleted from the store
		socket.on("delete rtp", data => {
			storeAPI.dispatch({
				type: "game/deleteReasonToPlay",
				payload: data,
			});
		});

		// recieves all game reasons to play
		socket.on("game rtp", data => {
			storeAPI.dispatch({
				type: "game/updateReasonsToPlay",
				payload: data,
			});
		});

		// recieves a reason in play to begin a bout of logical skirmish
		socket.on("bout begun", data => {
			storeAPI.dispatch({
				type: "game/boutBegun",
				payload: data,
			});
		});

		// sends a reason-in-play that has been editted to the redux store
		socket.on("updated rip", data => {
			storeAPI.dispatch({
				type: "game/updateReasonInPlay",
				payload: data,
			});
		});

		// update the player reason in play vote status
		socket.on("player rip vote", data => {
			storeAPI.dispatch({
				type: "game/updatePlayerRIPVoteStatus",
				payload: data,
			});
		});

		// sends the state containing votes of the reason in play to the redux store
		socket.on("rip vote", data => {
			storeAPI.dispatch({
				type: "game/updateReasonInPlay",
				payload: data,
			});
		});

		// recieves an updated common grounds and sends it to the redux store
		socket.on("common grounds", data => {
			storeAPI.dispatch({
				type: "game/updateCommonGrounds",
				payload: data,
			});
		});

		// recieves the state of the main claim when referee triggers final poll
		socket.on("updated endpoll", data => {
			storeAPI.dispatch({
				type: "game/setMainClaimState",
				payload: data,
			});
		});

		// recieves the winners of the game and sends to redux store
		socket.on("final results", data => {
			storeAPI.dispatch({
				type: "game/setFinalResults",
				payload: data,
			});
		});

		// recieves an error message from the server
		socket.on("error", data => {
			storeAPI.dispatch({
				type: "app/setError",
				payload: data,
			});
		});

		/**
		 * Data to be set to the server should be placed here
		 * Check for the type of action to emit to the server
		 */
		return next => action => {
			// tells the server to start a new game
			if (action.type === "game/emitStartGame") {
				socket.emit("initGame", action.payload);
				return;
			}

			// checks with server to see if room code is valid
			if (action.type === "app/emitIsRoomCodeValid") {
				socket.emit("isRoomCodeValid", action.payload);
				return;
			}

			// checks with server to see if player name valid
			if (action.type === "app/emitIsPlayerNameAvailable") {
				socket.emit("isPlayerNameAvailable", action.payload);
				return;
			}

			// puts player in the desired room to join the game
			if (action.type === "game/emitJoinGame") {
				socket.emit("joinRoom", action.payload);
				return;
			}

			if (action.type === "game/emitVoteConvinced") {
				socket.emit("voteConvinced", action.payload);
				return;
			}

			if (action.type === "game/emitVoteNotPersuaded") {
				socket.emit("voteNotPersuaded", action.payload);
				return;
			}

			// updates the main claim of the game
			if (action.type === "game/emitUpdateMainClaim") {
				socket.emit("initGame", action.payload);
				return;
			}

			// change the phase of the game
			if (action.type === "game/emitChangeGamePhase") {
				socket.emit("changeGamePhase", action.payload);
			}

			// sending a user added reason to play to the server
			if (action.type === "game/emitAddReasonToPlay") {
				socket.emit("addReasonToPlay", action.payload);
			}

			// send a reason to begin a bout or reason in play
			if (action.type === "game/emitBeginBout") {
				socket.emit("beginBout", action.payload);
			}

			// send an editted reason-to-play to the server
			if (action.type === "game/emitUpdateReasonToPlay") {
				socket.emit("updateReasonToPlay", action.payload);
			}

			// send an editted reason-to-play to the server
			if (action.type === "game/emitUpdatePriority") {
				socket.emit("updatePriority", action.payload);
			}

			// delete a reason-to-play to the server
			if (action.type === "game/emitDeleteReasonToPlay") {
				socket.emit("deleteReasonToPlay", action.payload);
			}

			// send an editted reason-in-play to the server
			if (action.type === "game/emitUpdateReasonInPlay") {
				socket.emit("updateReasonInPlay", action.payload);
			}

			// increase established vote for a Reason To Play
			if (action.type === "game/emitVoteEstablishedReasonInPlay") {
				socket.emit("voteEstablishedReasonInPlay", action.payload);
			}

			// increase contested vote for a Reason In Play
			if (action.type === "game/emitVoteContestedReasonInPlay") {
				socket.emit("voteContestedReasonInPlay", action.payload);
			}

			// move the current reason in play to common grounds
			if (action.type === "game/emitMoveRIPToCommonGround") {
				socket.emit("moveRIPToCommonGround", action.payload);
			}

			// request the server return a list of winners
			if (action.type === "game/emitGetFinalResults") {
				socket.emit("getFinalResults", action.payload);
			}

			return next(action);
		};
	};
};

export default createSocketMiddleware;
