import CommonGround from "./CommonGround";

function CommonGrounds(props) {
	const cgFor = props.commonGrounds.filter(cg => cg.type === "for");
	const cgAgainst = props.commonGrounds.filter(cg => cg.type === "against");
	const cgBoth = props.commonGrounds.filter(cg => cg.type === "both");

	const commonGroundFor = [...cgFor, ...cgBoth];
	const commonGroundAgainst = [...cgAgainst, ...cgBoth];

	return (
		<>
			<div className="lg:h-full h-72 p-2 overflow-y-auto rounded-lg shadow-lg">
				<CommonGround key="for" type="for" commonGround={commonGroundFor} />
			</div>
			<div className="lg:h-full h-72 p-2 overflow-y-auto rounded-lg shadow-lg">
				<CommonGround key="against" type="against" commonGround={commonGroundAgainst} />
			</div>
		</>
	);
}

export default CommonGrounds;
